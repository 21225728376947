@import "@style/theme.scss";

.container {
    cursor: pointer;
    display: inline-block;
    height: max-content;
}

.title {
    @include font-subscript;
    font-size: 12px;
    font-weight: 500;
    font-family: $font-secondary;
    color: $fg-secondary;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    & svg * {
        fill: $fg-secondary;
    }
}

.caret {
    margin-left: 5px;
    height: .75em;
    width: .75em;
}

.content {
    @include font-subscript;
    color: $fg-primary;
    overflow: hidden;
    transition: height .25s linear;
}