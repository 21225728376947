@import '@style/theme.scss';

.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-loader;
    border-radius: 0 0 7px 7px;
    background-color: #FFFFFF;
    opacity: 0.9;

    padding-top: 33%;

    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: center;
}

.loading {
    @include font-base;
    color: $fg-tertiary;
    margin: auto;
    width: 164px;
}