@import "@style/theme.scss";
@import "@style/media.scss";

.overlay {
    position: relative;
    // top: 0;
    // bottom: 0;
    // right: 0;
    // left: 0;
    min-height: 100vh;
    height: max-content;
    max-height: max-content;
    min-width: 100%;
    background-color: rgba(0,0,0,0.33);
    z-index: $z-index-overlay;
    padding-top: 15px;
    padding-bottom: 5%;
    // overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    @include media(">=md") {
        padding-top: 45px;
    }

    :global(body.embedded) & {
        padding: 0;
        height: unset;
        max-height: unset;
        background-color: transparent;

        &:not(.full-height) {
            min-height: unset;
        }
    }
}

// .transparent {
//     padding: 0;
//     height: unset;
//     min-height: unset;
//     max-height: unset;
//     background-color: transparent;

//     // @include media(">md+") {
//     //     padding: 20px 0;
//     // }
// }