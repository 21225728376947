@import "@style/media.scss";
@import "@style/grid.scss";

.full-width {
    @include grid-col-span("*-full");
}

$first: nth(map-keys($grid-max), 1);
$last: nth(map-keys($grid-max), length(map-keys($grid-max)));
$last-max: map-get($grid-max, $last);
@each $media-key, $max-cols in $grid-max {
    $operator: "<=";
    
    @for $cols from 1 through $max-cols {
        .lt-#{$media-key}-#{$cols} {
            @include media("<=#{$media-key}") {
                grid-column: span #{cols};
            }
        }
    }
    @for $cols from 1 through $last-max {
        .gt-#{$media-key}-#{$cols} {
            @include media(">=#{$media-key}") {
                grid-column: span #{cols};
            }
        }
    }
}