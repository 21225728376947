@import "@style/theme.scss";

$meter-height: 178px;
$meter-width: 240px;

.container {
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;

    &.no-hit {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        & > * {
            margin-bottom: 8px;
            text-align: center;
        }
    }
}

.gauge {
    // position: absolute;
    box-sizing: border-box;
    // top: 0;
    // left: 0;
    // right: 0;
    // height: $meter-height;
    // width: $meter-width;
    // margin: auto;

    // & > img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: contain;
    // }
}

.pointer {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 111px;
    left: $meter-width * 0.5;
    transform-origin: 0 50%;
    transition: all 1s ease-in;
    transform: translateZ(0);
}

.pointer-glyph {
    transform: translateX(-4.5px);
}

.detail-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
}

.detail-container {
    display: flex;
    flex-flow: row wrap;
}

.details * {
    align-content: center;
    text-align: center;
}

.score-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    @include media(">=sm+") {
        font-size: 20px;
        line-height: 24px;
    }

    @include media(">=md") {
        font-size: 28px;
        line-height: 38px;
    }
}

.sub-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
}

.score {
    font-weight: bold;
    color: $fg-primary;
    font-size: 43px;
    line-height: 50px;

    @include media(">=sm+") {
        font-size: 55px;
        line-height: 62px;
    }

    @include media(">=md") {
        font-size: 70px;
        line-height: 80px;
    }
}

.status {
    font-weight: bold;
    color: hsl(0, 100%, 50%);
    transition: color 1s ease-in;
    text-transform: uppercase;

    font-size: 22px;
    line-height: 29px;

    @include media(">=sm+") {
        font-size: 35px;
        line-height: 40px;
    }

    @include media(">=md") {
        font-size: 40px;
        line-height: 50px;
    }
}

.status-icon {
    display: flex;
    align-items: center;
    padding-left: 20px;

    @include emoji($size: large);

    &.no-hit {
        padding: 0;
    }
}

.score-date {
    font-family: "Poppins";
    opacity: 0.6;
    color: #7c7b7b;
    font-weight: 500;

    font-size: 12px;
    line-height: 17px;

    @include media(">=md") {
        font-size: 21px;
        line-height: 38px;
    }
}