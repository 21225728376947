@import "@style/media.scss";
@import "@style/theme.scss";

@mixin common {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal-content {
    @include media(">=2c") {
        min-height: 700px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }
}

.container {
    @include common;
}

.actions {
    @include media("<md") {
        padding-top: 20px;
    }
}

.emoji {
    width: 50px;

    @include media(">=md") {
        width: 75px;
    }
}

.title {
    @include common;
    @include font-title($secondary: true);
}

.message {
    @include common;
    @include font-base;

    @include media(">=md") {
        @include font-title;
        font-weight: normal;
    }
}

.bullet-header {
    @include font-base;
    font-weight: 600;
    font-size: 18px;
}

.list {
    width: 100%;
}

.list-item {
    @include font-base;
    padding: 5px 0px;
    text-align: start;
    line-height: 30px;
    font-size: 17px;
}

.list-item::marker {
    color: $fg-secondary;
    font-size: 26px;
}