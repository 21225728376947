@import "@style/media.scss";
@import "@style/theme.scss";

.modal {
    $form-width: 90;
    $width-diff: (100 - $form-width) * 0.01;
    $width-min: 320px;

    position: relative;
    min-width: $width-min - ($width-min * $width-diff);
    max-width: 600px;
    width: $form-width * 1%;
    margin: 0 auto;
    padding: 0 5px 5px 5px;
    border-radius: 20px;
    background-color: $bg-primary;
    z-index: $z-index-modal;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 40px auto auto;
    grid-template-areas: "header"
                         "content"
                         "footer";

    box-sizing: border-box;

    & .side-pane {
        display: none;
    }

    &.embedded {
        width: 100% !important;
    }

    &.nocontainer-embed {
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    @include with-side-pane('canonical') {
        @include media(">md+") {
            width: 95%;
        }

        @include media(">=2c") {
            display: grid;
            max-width: 1124px;
            grid-template-columns: auto 626px;
            grid-template-rows: 40px auto;
            grid-template-areas: "side header"
                                 "side content"
                                 "side footer";

            & .side-pane {
                display: block;
                position: relative;
                padding: 12px;
                grid-area: side;
            }
        }
    }
}

.form-buffer {
    width: 100%;
    padding: 20px;
}

.modal-header {
    grid-area: header;
    // @include with-cap-width {
    //     padding-left: 30px;
    // }
}

.modal-content {
    grid-area: content;
    position: relative;
    @include modal-padding;
}

.modal-footer {
    grid-area: footer;
    padding: 0px 15px;
    @include media(">=md") {
        padding: 0px 30px;
    }
    // padding-top: 20px;
}