@import "@style/theme.scss";

.content {
    // padding-bottom: 20px;
    box-sizing: border-box;
    margin: auto;

    @include with-cap-width {
        max-width: $modal-content-cap;
    }
}

.content-form {
    margin-block-end: 0;
}