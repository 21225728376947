@import '@style/theme.scss';

.spinner,
.spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.spinner {
    $spinner-width: 4px;
    margin: 15px auto;
    font-size: 4px;
    position: relative;
    border-top: $spinner-width solid rgba(255, 255, 255, 0);
    border-right: $spinner-width solid rgba(255, 255, 255, 0);
    border-bottom: $spinner-width solid $bg-tertiary;
    border-left: $spinner-width solid $bg-tertiary;
    transform: translateZ(0);
    animation: spin 1.1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}