@import "@style/media.scss";
@import "@style/theme.scss";

.container {
    &>button {
        @include media(">=md") {
            // width: 66%;
        }
    }
}

.msa-button {
    border-radius: 6px;
    color: #e8eaee;
    background: $fg-secondary;
}