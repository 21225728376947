@import '@style/theme.scss';

.actions {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-flow: column nowrap;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    text-align: center;
}