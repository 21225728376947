@import "@style/theme.scss";

.header {
    position: relative;
    padding: $gutter-sm;
    padding-bottom: 3px;
}

.info {
    background: linear-gradient(180deg, #D89AE2 0%, #B061C1 100%);
    text-align: center;
    vertical-align: center;
}

.clickable {
    cursor: pointer;
}

.entries {
    display: flex;
    flex-flow: column nowrap;
    max-height: 100%;
    padding: $gutter-sm;
    padding-top: 0;
    // overflow-y: auto;
    // padding-right: 10px;
    // margin-right: -10px;

    // box-shadow: 0 -10px 10px -10px inset rgba(0, 0, 0, 0.5);
    // -webkit-box-shadow: 0 -10px 10px -10px inset rgba(0, 0, 0, 0.5);

    // @include scroll;
}

.entry-border {
    border-bottom: 1px solid $border-color;
}

.entry {
    padding-top: 12px;
    transition: all .5s linear;

    &:not(:last-child) {
        @extend .entry-border;
    }
}

.entry-bold {
    @include font-bold;
}

.entry-summary {
    @include font-base;

    display: flex;
    flex-flow: row nowrap;
    user-select: none;

    .entry-summary-detail {
        flex-grow: 1;
        margin-bottom: 11.5px;
    }
    .entry-summary-icon {
        display: flex;
        margin-bottom: 11.5px;
        flex-flow: column nowrap;
        justify-content: center;
        flex-shrink: 1;

        & svg * {
            fill: $fg-secondary;
        }
    }
}

.entry-detail {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    transition: all .25s linear;
    overflow: hidden;
}

.entry-detail-segment {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    height: max-content;
    min-height: max-content;
    padding-top: 12px;

    &:last-child {
        padding-bottom: 11.5px;
    }
}

.entry-detail-title {
    @include font-label;
    font-weight: bold;
}
.entry-detail-info {
    display: flex;
    flex-flow: column nowrap;
}

.entry-detail-table {
    display: block;
}
.entry-detail-row {
    display: flex;
    flex-flow: row nowrap;
    line-height: 20px;
    & > .entry-detail-cell:first-child {
        padding-right: 2%;
    }
    & > .entry-detail-cell.entry-detail-table:first-child {
        width: 57%;
        text-align: left;
    }
    & > .entry-detail-cell.entry-detail-table:last-child {
        width: 45%;
        text-align: right;
    }
    .entry-detail-info-value {
        @include font-base;
        line-height: 20px;
        &.entry-detail-bold {
            @include font-bold;
            line-height: 20px;
        }
    }
    .entry-detail-info-value:not(.entry-detail-table) {
        display: contents;
    }

    &:not(:last-child) {
        padding-bottom: 8px;
    }
}