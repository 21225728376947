@import "@style/media.scss";
@import "@style/theme.scss";

.title {
    @include font-title-big;
    text-align: center;
}

.meter {
    background: linear-gradient(0deg, #B55DC4 0%, #320B4E 100%);
    height: 296px;
}

.equifax {
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
}

.equifax-subtitle {
    @include font-base;
    display: flex;
    flex-flow: row nowrap;
    justify-content: baseline;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 15px;

    & img {
        vertical-align: text-bottom;
    }
}

.summary {
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
}

.score-details {

    // max-height: 85vh;
    @include media(">=md") {
        max-height: 585px;
    }

    padding: 0;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
}

.app-button {
    margin: auto;
    margin-top: 20px;

    @include media(">=md") {
        width: 50%;
    }
}

.grid-columns {
    @include media(">=md") {
        grid-template-columns: minmax(auto, 240px) 1fr !important;
    }
}

.see-more-container {
    display: flex;
    width: 100%;
    margin-top: auto;
    border-top: 1px solid #E0E0E1;
}

.see-more-link {
    color: $fg-secondary;
    width: 100%;
    text-align: center;
    padding: 14px;
}