@import '@style/theme.scss';
@import '@style/media.scss';


.button {
    @include font-label;
    height: 48px;
    width: 100%;
    border-radius: 100px;

    background: $ava-button;
    color: #FFFFFF;
    border: none;
    outline: none;

    position: relative;
    overflow: hidden;

    cursor: pointer;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    &[disabled] {
        background: $border-color;
    }

    &:hover:enabled {
        &:not([disabled]) {
            background: $hover;
        }
    }

    &.animate::after {
        // display: none;
        display: block;
        content: "";
        position: absolute;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.3);

        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;

        top: 50%;
        left: 50%;

        animation: ripple 1s;
        opacity: 0;
    }

    height: auto;
    color: $bg-primary;
    background-color: $drive-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px;
    text-transform: capitalize;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(10);
    }
}

.icon-prefix {
    padding-right: 12px;
}

.icon-suffix {
    padding-left: 12px;
}

.spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.loading-spinner {
    margin: 0;
    border-bottom-color: $bg-primary;
    border-left-color: $bg-primary;
}