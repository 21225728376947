@import '@style/theme.scss';
@import '@style/media.scss';

.feedback-container {
    display: flex;
    width: 100%;
    background-color: $bg-primary;
    margin-top: 15px;
}

.feedback-dialog {
    display: flex;
    flex-direction: column;
    width: 87%;
    padding: 20px;
    border-radius: 14px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    @include media("<=md") {
        width: 100%;
    }
}

.feedback-input-label {
    @include font-title;
    text-align: left;
}

.feedback-input {
    @include font-base;
    padding: 10px;
    margin: 20px 0;
    border-radius: 8px;
    border-color: $border-color;
    resize: none;
}

.feedback-avatar-container {
    display: flex;

    @include media("<=md") {
        display: none;
    }
}

.img-container {
    display: flex;
    width: 100%;
    height: max-content;
    align-self: flex-end;
}

.feedback-submit {
    width: 175px;
    align-self: flex-end;
}
