@import '@style/theme.scss';
@import '@style/media.scss';

.main-container {
    // display: flex;
    // flex-direction: column;
    height: 100%;
    width: 100%;
    margin: auto;
    // align-items: center;

    padding-top: 33%;

    z-index: $z-index-error;

    @include media(">=md") {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding-top: 15%;
    }
}

.content {
    max-width: $modal-content-cap;
    padding: 15px 0;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    text-align: center;

    & > * {
        margin-bottom: 15px;
    }
}

.emoji-container {
    // margin-bottom: 10px;
    @include emoji($size: large);
}

.title-container {
    margin-bottom: 5px;
    text-align: center;
    // min-width: 270px;
    
    // @include media(">=md") {
    //     width: 500px;
    //     margin: 10px 0px;
    // }
}

.title {
    font-family: "Proxima Nova";
    font-size: 21px;
    line-height: 30px;
    
    @include media(">=md") {
        font-size: 32px;
        line-height: 45px;
    }
}

.subtitles-container {
    display: flex;
    flex-direction: column;
    // min-width: 270px;
    // margin: 10px 0px;
    justify-content: center;
    align-items: center;

    @include media(">=md") {
        width: 400px;
    }
}

.subtitle {
    // margin: 10px 0px;
    text-align: center;
    font-size: 15px;

    @include media(">=md") {
        font-size: 17px;
    }
}

.buttons-container {
    display: flex;
    flex-direction: column;
}

.button {
    // width: 253px;
    margin-bottom: 15px;
    // padding: 0px 15px;
    font-size: 13px;
    
    @include media(">=md") {
        font-size: 14px;
        // padding: 0px 35px;
    }
}

.close-btn {
    @include font-label;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    border: none;
    background: none;
    margin: 22px 0;
}