@import "@style/media.scss";

.grid {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-row-gap: 40px;

    &.c1 {
        grid-template-columns: 1fr;
    }

    &.c2 {
        @include media(">=md") {
            grid-template-columns: 1fr 1fr;
        }
    }
}