@import '@style/theme.scss';
@import '@style/media.scss';

.container {
    position: absolute;
    min-height: 100%;
    // top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    z-index: $z-index-error;
    border-radius: 0 0 7px 7px;
    background-color: fade-out($color: $bg-primary, $amount: 0.1);

    padding: 15px;
    padding-top: 33%;

    @include media(">=md") {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
}

.icon {
    @include emoji($size: large);
}

.content {
    max-width: $modal-content-cap;
    // max-height: 500px;
    // height: 100%;
    padding: 15px 0;

    margin: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    text-align: center;

    & > * {
        margin-bottom: 15px;
    }
}

.sub-title {
    @include font-base;

    @include media(">=md") {
        @include font-title;
        font-weight: normal;
    }
}

.button {
    width: 253px;
}