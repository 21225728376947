@import "./utils.scss";

$breakpoints: (
    "sm": 320px,
    "sm+": 380px,
    "md": 630px,
    "md+": 700px,
    "2c": 970px,
) !default;

$unit-intervals: (
    "px": 1,
    "em": 0.01,
    "rem": 0.1,
) !default;

@mixin media($conditions...) {
    @for $i from 1 through length($conditions) {
        $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
    }
    
    $query: "";

    @each $expression in $conditions {
      @if (str-length($query) != 0) {
        $query: $query + " and "
      }
      $query: $query + $expression
    }

    @media #{$query} {
        @content;
    }
}

@function parse-expression($expression) {
    $operator: "";
    $value: "";
    $element: "";
    $result: "";

    @if (type-of($expression) != "string") {
        @error 'Expression with type `#{type-of($expression)}` detected, string expected.';
    }

    // Separating the operator from the rest of the expression
    @if (str-slice($expression, 2, 2) == "=") {
        $operator: str-slice($expression, 1, 2);
        $value: str-slice($expression, 3);
    } @else {
        $operator: str-slice($expression, 1, 1);
        $value: str-slice($expression, 2);
    }

    // Checking what type of expression we're dealing with
    @if map-has-key($breakpoints, $value) {
        $result: map-get($breakpoints, $value);
    } @else {
        $result: to-number($value);
    }

    $unit: unit($result);
    $interval: 0;

    @if (map-has-key($unit-intervals, $unit)) {
        $interval: map-get($unit-intervals, $unit);
    } @else {
        @error 'Unknown unit: #{$unit}';
    }

    @if ($operator == ">") {
        $element: "(min-width: #{$result + $interval})";
    } @else if ($operator == "<") {
        $element: "(max-width: #{$result - $interval})";
    } @else if ($operator == ">=") {
        $element: "(min-width: #{$result})";
    } @else if ($operator == "<=") {
        $element: "(max-width: #{$result})";
    } @else {
        @error '#{$expression} is missing an operator.';
    }

    @return $element;
}