@import "@style/theme.scss";

.header {
    position: relative;
    padding: $gutter-sm;
    padding-bottom: 3px;
}

.info {
    background: linear-gradient(180deg, #D89AE2 0%, #B061C1 100%);
    text-align: center;
    vertical-align: center;
}

.entries {
    display: flex;
    flex-flow: column nowrap;
    max-height: 100%;
    padding: $gutter-sm;
    padding-top: 0;
    // overflow-y: auto;
    // padding-right: 15px;
    // margin-right: -15px;

    // @include scroll;
}

.detail-entry {
    @include font-base;
    padding-top: 12px;
    padding-bottom: 11.5px;
    & .detail-title {
        @include font-bold;
        font-weight: bold;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }
}