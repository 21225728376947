@import "./theme.scss";
@import "./media.scss";
@import "./utils.scss";

$grid-max: (
    "sm": 1,
    "md": 2,
);

@mixin grid-col-span($screen-sizes...) {
    @each $size in $screen-sizes {
        $media: str-slice($size, 1, str-index($size, "-") - 1);
        $cols: str-slice($size, str-index($size, "-") + 1);

        @if $media == "*" {
            @include get-col-span($cols);
        } @else {
            @include media($media) {
                @include get-col-span($cols);
            }
        }
    }
}

@mixin get-col-span($cols) {
    @if $cols == "full" {
        $first: nth(map-keys($grid-max), 1);
        @each $media-key, $max-cols in $grid-max {
            @if not map-has-key($breakpoints, $media-key) {
                @error "[style/grid.scss] Mismatch between breakpoints and grid steps!";
            }

            @if $media-key == $first {
                grid-column: 1 / span #{map-get($grid-max, $media-key)};
            } @else {
                @include media(">=#{$media-key}") {
                    grid-column: 1 / span $max-cols;
                }
            }
        }
    } @else {
        $cols: to-number($cols);
        grid-column: span $cols;
    }
}